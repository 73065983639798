/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { ReactElement, useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Name, MenuItem } from './styles';
import Linkedin from './in-icon.png';

let apiUrl = process.env.REACT_APP_API_URL;

if (!apiUrl) {
  apiUrl = '';
}

function Footer(): ReactElement {
  const [linkedin, setLinkedin] = useState('');

  const getContent = async () => {
    try {
      const response = await axios.get(`${apiUrl}/social-media`);
      // console.log(response);

      setLinkedin(response.data.linkedin);
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <Container id="footer">
      <Name>Monique Giggy</Name>
      <ul>
        <MenuItem href="#who-am-I">Who I am</MenuItem>
        <MenuItem href="#what-I-do">What do I do</MenuItem>
        <MenuItem href="#my-portfolio">My portfolio</MenuItem>
        <MenuItem href="#media-and-content">Media</MenuItem>
      </ul>
      <div>
        <img
          src={Linkedin}
          onClick={() => {
            window.location = linkedin as unknown as Location;
          }}
        />
      </div>
    </Container>
  );
}

export default Footer;
