import styled from 'styled-components';

export const Divider = styled.p`
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 5vw;
  color: rgba(0,0,0,0.2);
  /* width: 100vw; */
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3vw;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.05);
  }

  @media (max-width: 700px){//mobile
    font-size: 10.667vw;
    margin-top: 5vw;
  }
`;
