import styled from 'styled-components';

export const Container = styled.button`
  background-color: rgba(224, 53, 43, 0.6);
  backdrop-filter: blur(5px);
  height: 4.167vw;
  width: 4.167vw;
  position: fixed;
  left: 2vw;
  top: 1vw;
  border-radius: 0.2vw;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0.4167vw 0;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 700px){//mobile
    height: 13.067vw;
    width: 13.067vw;
    border-radius: 1vw;
    left: 5vw;
    top: 5vw;
  }
`;

export const WhiteStrap = styled.div`
  background-color: white;
  width: 80%;
  height: 7%;
  border-radius: 0.2vw;
`;

export const MenuModal = styled.div`
  background-color: rgba(255,255,255, 0.7);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  position: fixed;
  left: 0;
  top: 0;


  & > div {
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 700px){//mobile
      flex-direction: column-reverse;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 700px){//mobile
    margin-right: auto;
  }
`;

export const Name = styled.h2`
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 7.448vw;
  color: #000000;
  /* width: 34.479vw; */

  /* position: absolute;
  bottom: 1vh;
  left: 6.906vw; */


  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.07448);
    /* width: calc(1920px * 0.34479); */
    /* left: calc(1920px * 0.06906); */
  }

  @media (max-width: 700px){//mobile
    font-size: 16vw;
    position: relative;
    top: 6vw;
  }
`;

export const OrangeStrap = styled.div` 
  height: 1.042vw;
  width: 26.042vw;
  background-color: #BF2605;

  /* position: absolute;
  bottom: 1vh; */

  @media (min-width: 1920px) {
    height: calc(1920px * 0.01042);
    width: calc(1920px * 0.26042);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0.01);
  }

  @media (max-width: 700px){//mobile
    height: 4.267vw;
    width: 90.400vw;
  }
`;

export const MenuItems = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const MenuItem = styled.a` 
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 5vw;
  color: #000000;
  background-color: rgba(0,0,0,0);
  border: none;
  text-decoration: none;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.05);
    /* width: calc(1920px * 0.34479); */
    /* left: calc(1920px * 0.06906); */
  }

  @media (max-width: 700px){//mobile
    font-size: 10.667vw;
    margin: 4vw 0;
  }
`;

export const Close = styled.button` 
  position: fixed;
  top: 1vw;
  right: 5vh;
  background-color: rgba(0,0,0,0);
  border: none;
  font-size: 5vw;
  cursor: pointer;

  @media (max-width: 700px){//mobile
    font-size: 10vw;
    right: 5vw;
    top: 5vw;
  }
`;
