import styled from 'styled-components';
import Monique from './assets/Monique-black-and-white.png';

export const Container = styled.section`
  height: 105vh;
  width: 100%;
  max-height: min(1080px, 50vw);
  min-height: min(800px, 40vw);
  /* max-height: 1080px;
  max-width: 1920px; */
  /* margin: 0 auto; */
  /* position: relative; */
  overflow: hidden;

  & > div {
    height: 100%;
    width: 100%;
    max-height: min(1080px, 50vw);
    max-width: 1920px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 700px){//mobile
      max-height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: min(1100px, 190vw);
    }
  }

  @media (max-width: 700px){//mobile
    max-height: unset;
    min-height: min(1350px, 215vw);
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 0vh;
  left: 42vw;
  width: 61.667vw;
  height: 18vw;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1920px){
    left: calc(1920px * 0.42);
    width: calc(1920px * 0.61667);
    height: calc(1920px * 0.18);
  }
  @media (min-height: 1080px) {
    top: calc(1080px * 0.0);
  }

  @media (max-width: 700px){
    max-height: 100vh;
  }

  @media (max-width: 700px){ //mobile
    width: 100vw;
    height: 35vh;
    min-height: min(420px, 75vw);
    top: 0vh;
    left: 0;
  }
`;

export const YellowCircle = styled.div`
  height: 28.438vw;
  width: 28.438vw;
  border-radius: 50%;
  border: solid 0.260vw #F4D03A;

  position: absolute;
  right: 0;
  bottom: 0;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.28438);
    width: calc(1920px * 0.28438);
    border: solid calc(1920px * 0.00260vw) #F4D03A;
    right: 0;
  }

  @media (max-width: 700px){ //mobile
    width: 76.533vw;
    height: 76.533vw;
    border: solid 1vw #F4D03A;
    left: 38vw;
    bottom: 0;
  }
`;

export const Name = styled.h1`
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 7.448vw;
  color: #000000;
  width: 34.479vw;

  position: absolute;
  bottom: 1vh;
  left: 6.906vw;


  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.07448);
    width: calc(1920px * 0.34479);
    left: calc(1920px * 0.06906);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0.01);
  }

  @media (max-width: 700px){ //mobile
    font-size: 13.333vw;
    font-weight: 300;
    width: fit-content;
    bottom: 1vh;
    left: 1vw;
    margin-left: 8vw;
  }
`;

export const OrangeStrap = styled.div` 
  height: 1.042vw;
  width: 26.042vw;
  background-color: #BF2605;

  position: absolute;
  bottom: 1vh;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.01042);
    width: calc(1920px * 0.26042);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0.01);
  }

  @media (max-width: 700px){ //mobile
    /* font-size: 13.333vw; */
    width: 50.933vw;
    height: 2.133vw;
  }
`;

export const PhotoContainer = styled.div`
  position: absolute;
  bottom: 0vh;
  left: 0vw;
  width: 100vw;
  height: 37.500vw;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1920px) {
    left: calc(1920px * 0);
    width: calc(1920px * 1);
    height: calc(1920px * 0.37500);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0);
  }

  @media (max-width: 700px){ //mobile 
    height: 70vw;
  }
`;

export const YellowStrap = styled.div` 
  height: 1.042vw;
  width: 54.167vw;
  background-color: #F4D03A;

  position: absolute;
  top: 20vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.01042);
    width: calc(1920px * 0.54167);
    top: calc(1920px * 0.20);
  }

  @media (max-width: 700px){ //mobile
    height: 2.667vw;
    width: 78.133vw;
  }
`;

export const OrangeCircle = styled.div`
  height: 25.833vw;
  width: 25.833vw;
  border-radius: 50%;
  background-color: #BF2605;

  position: absolute;
  bottom: 0vh;
  left: 12.656vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.25833);
    width: calc(1920px * 0.25833);
    left: calc(1920px * 0.12656);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0);
  }

  @media (max-width: 700px){ //mobile
    height: 61.600vw;
    width: 61.600vw;
    bottom: 0;
    right: 60vw;
    left: unset;
  }
`;

export const MoniquePhoto = styled.div`
  background-image: url(${Monique});
  background-size: cover;
  height: 32.292vw;
  width: 25.156vw;
  position: absolute;
  top: 2vw;
  left: 20.469vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.32292);
    width: calc(1920px * 0.25156);
    left: calc(1920px * 0.20469);
    top: calc(1920px * 0.02);
  }
  /* @media (min-height: 1080px) {
    top: calc(1080px * 0.04);
  } */

  @media (max-width: 700px){ //mobile
    height: 77.067vw;
    width: 57.600vw;
    bottom: 17vw;
    left: 0;
    top: unset;
  }
`;

export const BlackStrap = styled.div` 
  height: 1.042vw;
  width: 81.615vw;
  background-color: black;

  position: absolute;
  top: 34vw;
  right: 0vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.01042);
    width: calc(1920px * 0.81615);
    top: calc(1920px * 0.34);
  }
  @media (min-height: 1080px) {
    /* top: calc(1080px * 0.32292); */
  }

  @media (max-width: 700px){ //mobile
    width: 100vw;
    height: 2.933vw;
    bottom: 17vw;
    left: 0;
    top: unset;
  }
`;

export const Quote = styled.p` 
  height: 2.552vw;
  width: 47.865vw;
  font-size: 2vw;
  white-space: pre-line;

  position: absolute;
  top: 21.5vw;
  right: 2vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.02552);
    width: calc(1920px * 0.47865);
    top: calc(1920px * 0.215);
    right: calc(1920px * 0.02);
    font-size: calc(1920px * 0.02);
  }

  @media (max-width: 700px){ //mobile
    /* position: static; */
    margin: 0 auto;
    font-size: 5vw;
    width: 80vw;
    top: unset;
    bottom: 13vw;
    right: 10.667vw;
    text-align: right;
  }
`;
