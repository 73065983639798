/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import {
  Container, BorderContainer, Image, Text, Tag,
} from './styles';
import DefaultImg from './default-img.png';

interface Props{
  img?: any,
  text?: string,
  tag?: string,
  ctaLink?: string,
}

function SlickCard({
  img = DefaultImg, text = 'lorem ipsum lorem ipsum lorem ipsum', tag = 'CTA', ctaLink = '',
}: Props): ReactElement {
  return (
    <Container>
      <BorderContainer>
        <Image src={img} />
        <Text>{text}</Text>
        <Tag href={ctaLink}>{tag}</Tag>
      </BorderContainer>
    </Container>
  );
}

export default SlickCard;
