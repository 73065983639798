import styled from 'styled-components';

export const Container = styled.section`
  margin: 10vw auto;
  max-width: 1920px;
  display: flex;
  justify-content: space-around;
  width: 90vw;

  @media (min-width: 1920px) {
    margin: calc(1920px * 0.1) auto;
  }
  @media (max-width: 700px){ //mobile
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.h2`
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;
  font-size: 5vw;
  color: #000000;
  width: 30%;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.05);
  }

  @media (max-width: 700px){ //mobile
    font-size: 13.333vw;
    white-space: nowrap;
    width: fit-content;
    margin-bottom: 10vw;

    br {
      display: none;
    }
  }
`;

export const SlickContainer = styled.div`
  width: 65%;
  margin-right: 5vh;
  
  @media (max-width: 700px){ //mobile
    margin-right: unset;
    width: 78.672vw;
  }
`;
