import styled from 'styled-components';

export const Container = styled.section`
  max-width: 1920px;
  height: 18vw;
  max-height: 400px;
  margin: 5vw auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 700px){//mobile
    height: fit-content;
    max-height: unset;
    margin: 20vw auto;
  }
`;

export const Title = styled.h1`
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 7.448vw;
  color: #000000;
  width: 34.479vw;
  margin-left: 10vw;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.07448);
    width: calc(1920px * 0.34479);
    left: calc(1920px * 0.06906);
    margin-left: calc(1920px * 0.10);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0.01);
  }

  @media (max-width: 700px){//mobile
    font-size: 13.333vw;
    width: unset;
    margin-bottom: 10vw;
  }
`;

export const SlickItemsContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 77.867vw;
`;
