import React, { ReactElement, useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  ContentTitle,
  ContentText,
  FirstLine,
  FirstLineContent,
  FirstLineImg,
  SecondLine,
  SecondLineImg,
  SecondLineContent,
} from './styles';

let apiUrl = process.env.REACT_APP_API_URL;

if (!apiUrl) {
  apiUrl = '';
}

function Content(): ReactElement {
  const [whoAmI, setWhoAmI] = useState('');
  const [whatIDo, setWhatIDo] = useState('');

  const getContent = async () => {
    try {
      const response1 = await axios.get(`${apiUrl}/who-am-i`);
      setWhoAmI(response1.data.text);
      const response2 = await axios.get(`${apiUrl}/what-i-do`);
      setWhatIDo(response2.data.text);
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <Container className="Content">
      <div>
        <FirstLine id="who-am-I">
          <FirstLineContent>
            <ContentTitle>You found Me</ContentTitle>
            <ContentText>
              {whoAmI}
            </ContentText>
          </FirstLineContent>
          <FirstLineImg />
        </FirstLine>
        <SecondLine id="what-I-do">
          <SecondLineImg />
          <SecondLineContent>
            <ContentTitle>What I&#39;m about</ContentTitle>
            <ContentText>
              {whatIDo}
            </ContentText>
          </SecondLineContent>
        </SecondLine>
      </div>
    </Container>
  );
}

export default Content;
