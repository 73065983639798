import React, { ReactElement, useState } from 'react';
import axios from 'axios';
import {
  Container,
  MoniquePhotoContainer,
  MoniquePhoto,
  OrangeStrap,
  Form,
  FormText,
  Input,
  TextArea,
  Button,
} from './styles';
import Monique from './monique.png';

let apiUrl = process.env.REACT_APP_API_URL;

if (!apiUrl) {
  apiUrl = '';
}

function Contact(): ReactElement {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const postContent = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await axios.post(`${apiUrl}/messages`, {
        name,
        email,
        message,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return (
    <Container id="contact">
      {/* <div> */}
      <MoniquePhotoContainer>
        <MoniquePhoto src={Monique} />
        <OrangeStrap />
      </MoniquePhotoContainer>
      <Form>
        <FormText>Leave a message</FormText>
        <Input placeholder="Name" value={name} onChange={(e) => { setName(e.target.value); }} />
        <Input placeholder="Email" type="email" value={email} onChange={(e) => { setEmail(e.target.value); }} />
        <TextArea className="message" placeholder="Message" value={message} onChange={(e) => { setMessage(e.target.value); }} />
        <Button onClick={postContent}>
          Send
        </Button>
      </Form>
      {/* </div> */}
    </Container>
  );
}

export default Contact;
