import React, { ReactElement, useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TitleContainer,
  PhotoContainer,
  Name,
  MoniquePhoto,
  YellowStrap,
  OrangeCircle,
  YellowCircle,
  OrangeStrap,
  BlackStrap,
  Quote,
} from './styles';

let apiUrl = process.env.REACT_APP_API_URL;

if (!apiUrl) {
  apiUrl = '';
}

function Banner(): ReactElement {
  const [quote, setQuote] = useState('“You never change things by fighting the existing reality. To change something, build a new model that makes the existing model obsolete.” \n - Buckminster Fuller');

  const getContent = async () => {
    try {
      const response = await axios.get(`${apiUrl}/quote`);
      // console.log(response);

      setQuote(response.data.quote);
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getContent();
  }, []);
  return (
    <Container id="banner">
      <div>
        <TitleContainer>
          <div>
            <YellowCircle />
            <Name>Monique Giggy</Name>
            <OrangeStrap />
          </div>
        </TitleContainer>

        <PhotoContainer>
          <div>
            <YellowStrap />
            <OrangeCircle />
            <MoniquePhoto />
            <BlackStrap />
            <Quote>
              {quote}
            </Quote>
          </div>
        </PhotoContainer>
      </div>
    </Container>
  );
}

export default Banner;
