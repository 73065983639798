/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import React, { ReactElement, useState } from 'react';
import Slider from 'react-slick';
import { Container } from './styles';
import LeftArrow from './assets/left-arrow.png';
import RightArrow from './assets/right-arrow.png';

interface Props{
  children: React.ReactElement[],
  isMedia?: boolean,
}

function Slick({ children, isMedia }:Props): ReactElement {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  window.addEventListener('resize', () => {
    setScreenWidth(window.screen.width);
  }, true);

  return (
    <Container className={isMedia ? 'media' : 'portfolio'}>
      <Slider
        infinite
        // eslint-disable-next-line no-nested-ternary
        slidesToShow={isMedia ? (screenWidth <= 700 ? 1 : 3) : (screenWidth <= 700 ? 1 : 5)}
        slidesToScroll={1}
        prevArrow={<img src={LeftArrow} />}
        nextArrow={<img src={RightArrow} />}
        swipeToSlide
      >
        {children}
      </Slider>
    </Container>
  );
}

export default Slick;
