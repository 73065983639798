import React, { ReactElement, useState } from 'react';
import {
  Container, WhiteStrap, MenuModal, NameContainer, Name, OrangeStrap, MenuItems, MenuItem, Close,
} from './styles';

function Menu(): ReactElement {
  const [menu, setMenu] = useState(false);
  return (
    <>
      {menu && (
        <MenuModal>
          <div>
            <Close onClick={() => { setMenu(false); }}>X</Close>
            <NameContainer>
              <Name>Monique Giggy</Name>
              <OrangeStrap />
            </NameContainer>
            <MenuItems>
              <MenuItem href="#banner" onClick={() => { setMenu(false); }}>Home</MenuItem>
              <MenuItem href="#who-am-I" onClick={() => { setMenu(false); }}>You Found Me</MenuItem>
              <MenuItem href="#what-I-do" onClick={() => { setMenu(false); }}>What I&#39;m about</MenuItem>
              <MenuItem href="#my-portfolio" onClick={() => { setMenu(false); }}>My portfolio</MenuItem>
              <MenuItem href="#media-and-content" onClick={() => { setMenu(false); }}>media and content</MenuItem>
              <MenuItem href="#contact" onClick={() => { setMenu(false); }}>Contact</MenuItem>
            </MenuItems>
          </div>
        </MenuModal>
      )}
      <Container onClick={() => { setMenu(true); }}>
        <WhiteStrap />
        <WhiteStrap />
        <WhiteStrap />
      </Container>
    </>
  );
}

export default Menu;
