/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Title, SlickContainer } from './styles';
import { Slick, SlickCard } from '../../components';

let apiUrl = process.env.REACT_APP_API_URL;

if (!apiUrl) {
  apiUrl = '';
}

function MediaAndContent(): ReactElement {
  const [cards, serCards] = useState<any[]>([]);

  const getContent = async () => {
    try {
      const response = await axios.get(`${apiUrl}/cards`);
      const repetitions = Math.ceil(4 / response.data.length);
      const aux: any[] = [];
      for (let index = 0; index < repetitions; index += 1) {
        response.data.forEach((element: any) => {
          aux.push(element);
        });
      }
      serCards(aux);
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <Container id="media-and-content">
      <Title>
        Media and
        <br />
        {' '}
        content
      </Title>
      <SlickContainer>
        <Slick isMedia>
          {cards.map((element: any) => (
            <SlickCard
              text={element.title}
              img={element.img.url}
              tag={element.ctaText}
              ctaLink={element.ctaLink}
            />
          ))}
        </Slick>
      </SlickContainer>
    </Container>
  );
}

export default MediaAndContent;
