import styled from 'styled-components';

export const Container = styled.footer`
  height: 12.500vw;
  width: 96.875vw;
  border: 0.4vw solid #E0352B;
  margin: 1vw auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1920px;
  max-height: 240px;

  @media (min-width: 1920px) {
    border:calc(1920px * 0.004) solid #E0352B;
  }

  img{
    height: auto;
    width: auto;
    max-height: 3vw;
    max-height: 3vw;
    cursor: pointer;
    @media (min-width: 1920px) {
      max-height: calc(1920px * 0.03);
      max-height: calc(1920px * 0.03);
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    width: 10vw;

    @media (min-width: 1920px) {
      width: calc(1920px * 0.1);
    }
  }

  ul {
    height: fit-content;
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (min-width: 1920px) {
      width: calc(1920px * 0.4);
    }
  }

  @media (max-width: 700px){ //mobile
    height: 146.133vw;
    width: 78.667vw;
    margin: 10vw auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 4px solid #E0352B;
    max-width: unset;
    max-height: unset;

    div {
      display: flex;
      justify-content: space-between;
      width: 25vw;
    }

    img{
      height: auto;
      width: auto;
      max-height: 10vw;
      max-height: 10vw;
    }

    ul {
      height: 65vw;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
`;

export const Name = styled.h2`
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;
  font-size: 6vw;
  color: #000000;
  /* width: 34.479vw; */

  /* position: absolute;
  bottom: 1vh;
  left: 6.906vw; */


  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.06);
    width: calc(1920px * 0.34479);
    left: calc(1920px * 0.06906);
  }
  @media (min-height: 1080px) {
    bottom: calc(1080px * 0.01);
  }

  @media (max-width: 700px){ //mobile
    font-size: 12vw;
  }

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.06);
  }
`;

export const MenuItem = styled.a`
  border: none;
  background-color: rgba(0,0,0,0);
  font-size: 1.2vw;
  text-decoration: none;
  color: black;

  @media (max-width: 700px){ //mobile
    font-size: 5.5vw;
  }

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.012);
  }
`;
