import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 31.302vw;
  overflow: hidden;
  max-width: 1920px;
  margin: 5vw auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    /* height: 100%;
    width: 100%;
    max-height: min(1080px, 50vw);
    max-width: 1920px;
    margin: 0 auto; */
    /* position: relative; */
  }
  @media (min-width: 1920px) {
    height: calc(1920px * 0.31302);
  }

  @media (max-width: 700px){ //mobile
    flex-direction: column-reverse;
    height: fit-content;
  }
`;

export const MoniquePhotoContainer = styled.div`
  position: relative;
  height: 31.302vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.31302);
  }
  @media (max-width: 700px){ //mobile
    height: 75.400vw;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const MoniquePhoto = styled.img`
  height: 31.302vw;
  width: 36.615vw;
  position: absolute;
  bottom: 0;
  left: 15.938vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.31302);
    width: calc(1920px * 0.36615);
    left: calc(1920px * 0.15938);
  }
  @media (max-width: 700px){ //mobile
    height: 74.400vw;
    width: 86.667vw;
    position: static;
  }
`;

export const OrangeStrap = styled.div` 
  height: 0.521vw;
  width: 87.500vw;
  background-color: #BF2605;

  position: absolute;
  bottom: 0;
  left: 0;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.00521);
    width: calc(1920px * 0.87500);
  }
  @media (min-height: 1080px) {
    bottom: 0;
  }

  @media (max-width: 700px){ //mobile
    height: 1.333vw;
    width: 100vw;
    position: static;
  }
`;

export const Form = styled.form` 
  height: 22.969vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 12.500vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.22969);
    margin-right: calc(1920px * 0.12500);
  }

  @media (max-width: 700px){ //mobile
    height: fit-content;
    margin-right: unset;
    margin-bottom: 5vw;
  }
`;

export const FormText = styled.h3` 
  align-self: flex-start;
  font-size: 2.083vw;
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.02083);
  }

  @media (max-width: 700px){ //mobile
    font-size: 13.333vw;
  }
`;

export const Input = styled.input` 
  font-family: 'Montserrat', sans-serif;
  height: 2.604vw;
  width: 20.833vw;
  margin: 0.625vw 0;
  background-color: rgb(247, 226, 224);
  border: solid 1px rgb(232, 137, 132);
  border-radius: 0.2vw;
  box-sizing: border-box;
  padding: 0.5vw 1vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.02604);
    width: calc(1920px * 0.202604);
  }
  @media (max-width: 700px){ //mobile
    height: 13.333vw;
    width: 78.667vw;
    border-radius: 1vw;
    margin: 3.200vw 0;
  }
`;

export const TextArea = styled.textarea` 
  font-family: 'Montserrat', sans-serif;
  height: 6.771vw;
  width: 20.833vw;
  margin: 0.625vw 0;
  background-color: rgb(247, 226, 224);
  border: solid 1px rgb(232, 137, 132);
  border-radius: 0.2vw;
  box-sizing: border-box;
  padding: 0.5vw 1vw;
  resize: none;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.06771);
    width: calc(1920px * 0.20833);
  }
  @media (max-width: 700px){ //mobile
    height: 34.667vw;
    width: 78.667vw;
    border-radius: 1vw;
    margin: 3.200vw 0;
  }
`;

export const Button = styled.button`
  background-color: #BF2605;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vw;
  width: 4vw;
  border-radius: 0.2vw;
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 1.2vw;
  color: white;
  border: none;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.02);
    width: calc(1920px * 0.04);
    border-radius: calc(1920px * 0.002);
    font-size: calc(1920px * 0.012);
  }

  @media (max-width: 700px){ //mobile
    height: 10vw;
    width: 20vw;
    border-radius: 1vw;
    font-size: 6vw;
  }
`;
