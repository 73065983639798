import styled from 'styled-components';

export const Container = styled.div`
  .slick-arrow{
    height: 4.479vw;
    width: 1.250vw;

    @media (min-width: 1920px) {
      height: calc(1920px * 0.04479);
      width: calc(1920px * 0.01250);
    }
    @media (max-width: 700px){ //mobile
      height: 22.933vw;
      width: 6.269vw;
      z-index: 1;
    }
  }

  .slick-track > div{
    display: flex;
    justify-content: center
  }

  &.portfolio{
    width: 88.802vw;
    width: 80%;
    margin: 0 auto;

    .slick-list{
      width: 75.573vw;
      width: 95%;
      margin: 0 auto;
    }

    .slick-track img{
      height: auto;
      width: auto !important;
      max-height: 4.479vw;
      margin: 0 auto !important;
      @media (min-width: 1920px) {
        max-height: calc(1920px * 0.04479);
      }
      @media (max-width: 700px){//mobile
        max-height: 17.067vw;
      }
    }
  }

  &.media{
    width: 100%;
  }
  
`;
