import styled from 'styled-components';
import Monique from './assets/Monique-blue-and-red.png';
import Bubbles from './assets/bubbles.png';

export const Container = styled.section`
  height: 58.802vw;
  width: 100%;
  max-height: 1080px;
  overflow: hidden;
  margin: 5vw 0;

  & > div {
    height: 100%;
    width: 100%;
    max-width: min(1550px, 80.729vw);
    margin: 0 auto;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 700px){//mobile
      max-width: unset;
      align-items: center;
    }
  }

  @media (min-width: 1920px) {
    margin: calc(1920px * 0.05) 0;
  }

  @media (max-width: 700px){//mobile
    max-height: unset;
    height: fit-content;
    margin: 20vw 0;
  }
`;

export const ContentTitle = styled.h3`
  color: black;
  font-size: 3.125vw;
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.03125);
  }

  @media (max-width: 700px){//mobile
    font-size: 13.333vw;
  }
`;

export const ContentText = styled.p`
  color: #0A0A0A;
  font-size: 1.146vw;
  width: 41.927vw;
  height: 17vw;

  @media (min-width: 1920px) {
    width: calc(1920px * 0.41927);
    height: calc(1920px * 0.11406);
    font-size: calc(1920px * 0.17);
  }

  @media (max-width: 700px){//mobile
    width: 81.333vw;
    font-size: 4.267vw;
    height: fit-content;
  }
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: 21.927vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.21927);
  } */
  @media (max-width: 700px){//mobile
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const FirstLineContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px){//mobile
    align-items: center;
  }
`;

export const FirstLineTag = styled.a`
  background-color: #BF2605;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vw;
  width: fit-content;
  border-radius: 0.2vw;
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 1.2vw;
  text-decoration: none;
  color: white;
  white-space: nowrap;
  padding: 0 1vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.02);
    width: fit-content;
    border-radius: calc(1920px * 0.002);
    font-size: calc(1920px * 0.012);
  }

  @media (max-width: 700px){//mobile
    align-self: flex-start;
    height: 10vw;
    width: fit-content;
    padding: 0 3vw;
    border-radius: 1vw;
    font-size: 6vw;
    margin-top: 5vw;
    margin-bottom: 20vw;
  }
`;

export const FirstLineImg = styled.div`
  display: flex;
  justify-content: space-between;
  height: 21.927vw;
  width: 22.188vw;

  background-image: url(${Monique});
  background-size: cover;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.21927);
    width: calc(1920px * 0.22188);
  }

  @media (max-width: 700px){//mobile
    height: 57.333vw;
    width: 58.133vw;
  }
`;

export const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px){//mobile
    flex-direction: column;
  }
`;

export const SecondLineImg = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30.781vw;
  width: 27.396vw;

  background-image: url(${Bubbles});
  background-size: cover;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.30781);
    width: calc(1920px * 0.27396);
  }

  @media (max-width: 700px){//mobile
    height: 65.333vw;
    width: 58.133vw;
  }
`;

export const SecondLineContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.20);
  }

  @media (max-width: 700px){//mobile
    height: fit-content;
  }
`;

export const IDoText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 2.083vw;
  position: absolute;
  bottom: 0.729vw;
  right: 0;

  @media (min-width: 1920px) {
    bottom: calc(1920px * 0.00729);
    font-size: calc(1920px * 0.02083);
  }

  @media (max-width: 700px){//mobile
    font-size: 10.667vw;
  }
`;

export const IDoStrap = styled.div`
  height: 0.365vw;
  width: 19.375vw;
  background-color: #E1AC25;
  position: relative;
  right: 15.5vw;
  margin-top: 2.5vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.00365);
    width: calc(1920px * 0.19375);
    right: calc(1920px * 0.155);
    margin-top: calc(1920px * 0.025);
  }

  @media (max-width: 700px){//mobile
    height: 1.867vw;
    width: 35.067vw;
    margin-top: 15vw;
    margin-bottom: 3vw;
  }
`;
