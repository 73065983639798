import styled from 'styled-components';

export const Container = styled.div`
  height: 19.167vw;
  width: 18.167vw;
  background-color: rgb(230,230,230);
  border-radius: 0.3vw;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8vw;
  box-sizing: border-box;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.19167);
    width: calc(1920px * 0.18167);
    border-radius: calc(1920px * 0.003);
    padding: calc(1920px * 0.008);
  }

  @media (max-width: 700px){ //mobile
    height: 69.973vw;
    width: 59.915vw;
    border-radius: 2vw;
    padding: 2.667vw;
  }
`;

export const BorderContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0.8vw;
  box-sizing: border-box;
  background-color: rgb(230,230,230);
  border-radius: 0.3vw;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1920px) {
    border-radius: calc(1920px * 0.003);
    padding: calc(1920px * 0.008);
  }

  @media (max-width: 700px){ //mobile
    border-radius: 2vw;
    padding: 2.667vw;
  }
`;

export const Image = styled.img`
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  background-color: rgb(230,230,230);
  border-radius: 0.3vw;

  @media (min-width: 1920px) {
    border-radius: calc(1920px * 0.003);
  }

  @media (max-width: 700px){ //mobile
    border-radius: 2vw;
  }
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 1.3vw;

  @media (min-width: 1920px) {
    font-size: calc(1920px * 0.013);
  }

  @media (max-width: 700px){ //mobile
    font-size: 5vw;
  }
`;

export const Tag = styled.a`
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vw;
  width: fit-content;
  border-radius: 0.2vw;
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  font-size: 1.2vw;
  color: white;
  align-self: flex-start;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 1vw;

  @media (min-width: 1920px) {
    height: calc(1920px * 0.02);
    width: fit-content;
    border-radius: calc(1920px * 0.002);
    font-size: calc(1920px * 0.012);
  }

  @media (max-width: 700px){ //mobile
    height: 9.333vw;
    width: fit-content;
    padding: 0 3vw;
    font-size: 5.333vw;
    border-radius: 1vw;
  }
`;
