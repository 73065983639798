/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/alt-text */
import React, { ReactElement, useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Title, SlickItemsContainer } from './styles';
import { Slick } from '../../components';

let apiUrl = process.env.REACT_APP_API_URL;

if (!apiUrl) {
  apiUrl = '';
}

interface responseTypes {
  img: {
    url: string;
  };
  ctaLink: string;
}

function MyPortfolio(): ReactElement {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [imgs, setImgs] = useState<responseTypes[]>([]);
  const [imgsMobile, setImgsMobile] = useState<responseTypes[][]>([]);

  window.addEventListener('resize', () => {
    setScreenWidth(window.screen.width);
  }, true);

  const getContent = async () => {
    try {
      const response = await axios.get(`${apiUrl}/portfolios`);
      const repetitions = Math.ceil(6 / response.data.length);
      const aux: responseTypes[] = [];
      for (let index = 0; index < repetitions; index += 1) {
        response.data.forEach((element: responseTypes) => {
          aux.push(element);
        });
      }
      let count = 0;
      const mobileSlides = Math.ceil(aux.length / 3);
      const auxMobile = [];
      for (let index = 0; index < mobileSlides; index += 1) {
        const auxMobileElement = [];
        for (let i = 0; i < 3; i += 1) {
          if (count < aux.length) {
            auxMobileElement.push(aux[count]);
            count += 1;
          }
        }

        auxMobile.push(auxMobileElement);
      }

      setImgs(aux);
      setImgsMobile(auxMobile);
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <Container id="my-portfolio">
      <Title>My Projects & Portfolio</Title>
      {screenWidth <= 700 ? (
        <Slick>
          {imgsMobile.map((element) => (
            <SlickItemsContainer>
              {element.map((element2) => (
                <a href={element2.ctaLink} target="_blank" rel="noreferrer">
                  <img src={element2.img?.url} />
                </a>
              ))}
            </SlickItemsContainer>
          ))}
        </Slick>
      ) : (
        <Slick>
          {imgs.map((element) => (
            <a href={element.ctaLink} target="_blank" rel="noreferrer">
              <img src={element.img?.url} />
            </a>
          ))}
        </Slick>
      )}
    </Container>
  );
}

export default MyPortfolio;
