import React, { ReactElement } from 'react';
import { Divider } from './styles';

function BannerDivider(): ReactElement {
  return (
    <Divider>
      Kindness Crypto Exponential Technologies Impact Global Startups Innovation
    </Divider>
  );
}

export default BannerDivider;
