import React, { ReactElement } from 'react';
import {
  Menu,
  Banner,
  BannerDivider,
  Content,
  MyPortfolio,
  MediaAndContent,
  Contact,
  Footer,
} from './views';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App(): ReactElement {
  return (
    <>
      <Menu />
      <Banner />
      <BannerDivider />
      <Content />
      <MyPortfolio />
      <MediaAndContent />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
